import { registerApplication, start, navigateToUrl } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";

const organization = "ecopetrol";
const isAux = "true";

const data = {
  props: {
    organization,
    isAux,
  },
};

const routes = constructRoutes(microfrontendLayout, data);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);
layoutEngine.activate();

function protectRoutes() {
  
  window.addEventListener('single-spa:before-routing-event', (event) => {
    const isAuthenticated = localStorage.getItem('isAuth') === 'true';
    const newUrl = new URL(event.detail.newUrl);
    const isPublicRoute = newUrl.pathname === '/';
    const isAppRoute = newUrl.pathname.startsWith('/app');
    const isLoginRedirect = newUrl.hash.includes('#code');
    const is404Route = newUrl.pathname === '/404';

    if (!isAuthenticated && !isPublicRoute && !isLoginRedirect) {
      navigateToUrl('/');
      return;
    }

    if (isAuthenticated && !isAppRoute && !isPublicRoute && !isLoginRedirect && !is404Route) {
      navigateToUrl('/404');
      return;
    }
  });
}

//protectRoutes();

start();
